import Vue from "vue";
import VueRouter from "vue-router";
import LoginHome from "../views/home.vue"; // 首页结构
import HomePage from "../views/homePage.vue"; // 首页内容
import ProductCenter from "../views/productCenter.vue"; // 产品中心
import CiphertextCamera from "../views/ciphertextCamera.vue"; // 加密摄像头
import IdentityReading from "../views/IdentityReading.vue"; // 加密身份读取
import EncryptedDatabase from "../views/encryptedDatabase.vue"; // 加密数据库
import EncryptionServer from "../views/encryptionServer.vue"; // 加密服务器
import EncryptionChip from "../views/encryptionChip.vue"; // 加密芯片
import NewsTrends from "../views/newsTrends.vue"; // 新闻动态
import NewDetailsOne from "../views/newDetailsOne.vue"; // 新闻动态详情一
import NewDetailsTwo from "../views/newDetailsTwo.vue"; // 新闻动态详情二
import NewDetailsThree from "../views/newDetailsThree.vue"; // 新闻动态详情三
import NewDetailsFour from "../views/newDetailsFour.vue"; // 新闻动态详情四
import CompanyIntroduction from "../views/companyIntroduction.vue"; // 公司介绍
import JoinUs from "../views/joinUs.vue"; // 加入我们
import productCar from "../views/product/productCar.vue"; // 产品服务-网约车
import Ciphertextface from "../views/product/ciphertextface.vue"; // 产品服务-密文人脸
import Rfid from "../views/product/rfid.vue"; // 产品服务-rfid
import BrainPower from "../views/product/brainPower.vue"; // 产品服务-智能汽车

import EntranceGuard from "../views/product/entranceGuard.vue"; // 产品服务-门禁
import Archive from "../views/product/archive.vue"; // 产品服务-数据库
import IndustrialControl from "../views/product/industrialControl.vue"; // 产品服务-工控
import CloudStorage from "../views/product/cloudStorage.vue"; // 产品服务-云存储
// 解决方案
import MedicalTreatment from "../views/option/medicalTreatment.vue"; // 解决方案-医疗
import GovernmentAffairs from "../views/option/governmentAffairs.vue"; // 解决方案-政务
import Electricity from "../views/option/electricity.vue"; // 解决方案-电力
import Finance from "../views/option/finance.vue"; // 解决方案-金融
import Industry from "../views/option/industry.vue"; // 解决方案-工业
import DataInteraction from "../views/option/dataInteraction.vue"; // 解决方案-数据交互
import Privacy from "../views/option/privacy.vue"; // 解决方案-隐私
import PrivacyInterconnect from "../views/PrivacyInterconnect/index.vue"; // 隐私互联
import Customization from "../components/customization.vue"; // 定制化

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    redirect: "/home/HomePage",
    // component: () => import('@/views/home.vue'),
    component: LoginHome,
    children: [
      { path: "HomePage", component: HomePage },
      { path: "ProductCenter", component: ProductCenter },
      { path: "CiphertextCamera", component: CiphertextCamera },
      { path: "IdentityReading", component: IdentityReading },
      { path: "EncryptedDatabase", component: EncryptedDatabase },
      { path: "EncryptionServer", component: EncryptionServer },
      { path: "EncryptionChip", component: EncryptionChip },
      { path: "NewsTrends", component: NewsTrends },
      { path: "NewDetailsOne", component: NewDetailsOne },
      { path: "NewDetailsTwo", component: NewDetailsTwo },
      { path: "NewDetailsThree", component: NewDetailsThree },
      { path: "NewDetailsFour", component: NewDetailsFour },
      { path: "CompanyIntroduction", component: CompanyIntroduction },
      { path: "JoinUs", component: JoinUs },
      { path: "productCar", component: productCar }, // 产品服务-网约车
      { path: "Ciphertextface", component: Ciphertextface }, // 产品服务-密文人脸
      { path: "Rfid", component: Rfid }, // rfid
      { path: "BrainPower", component: BrainPower }, // 产品服务-智能汽车
      { path: "EntranceGuard", component: EntranceGuard }, // 产品服务-门禁
      { path: "Archive", component: Archive }, // 产品服务-数据库
      { path: "IndustrialControl", component: IndustrialControl }, // 产品服务-工控
      { path: "CloudStorage", component: CloudStorage }, // 产品服务-云存储
      { path: "MedicalTreatment", component: MedicalTreatment }, // 解决方案-医疗
      { path: "GovernmentAffairs", component: GovernmentAffairs }, // 解决方案-政务
      { path: "Electricity", component: Electricity }, // 解决方案-电力
      { path: "Finance", component: Finance }, // 解决方案-金融
      { path: "Industry", component: Industry }, // 解决方案-工业
      { path: "DataInteraction", component: DataInteraction }, // 解决方案-数据交互
      { path: "Privacy", component: Privacy }, // 解决方案-数据交互
      { path: "PrivacyInterconnect", component: PrivacyInterconnect }, // 隐私互联
    ],
  },
  {
    path: "/Customization",
    name: "Home",
    component: Customization,
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    // 如果目标路由含有锚点，滚动到该锚点位置
    // console.log(savedPosition);
    if (savedPosition) {
      return savedPosition;
    } else {
      // 滚动到页面顶部
      return { x: 0, y: 0 };
    }
  },
  routes,
});
router.beforeEach((to, from, next) => {
  // to 和 from 是路由对象，包含当前路由和目标路由信息
  // console.log(`Navigating to ${to.fullPath} from ${from.fullPath}`);
  // console.log(to.fullPath);
  // console.log(to.fullPath.substring(0, to.fullPath.indexOf("?")));
  if (to.fullPath == "/home/HomePage") {
    window.sessionStorage.setItem("active", "1");
    window.sessionStorage.setItem("showBackToTop", "3");
    window.sessionStorage.setItem("tabflag", "true");
  } else if (to.fullPath == "/home/ProductCenter") {
    window.sessionStorage.setItem("active", "5");
    window.sessionStorage.setItem("showBackToTop", "2");
    window.sessionStorage.setItem("tabflag", "false");
  } else if (to.fullPath == "/home/PrivacyInterconnect" ){
    window.sessionStorage.setItem("active", "7");
    window.sessionStorage.setItem("showBackToTop", "2");
    window.sessionStorage.setItem("tabflag", "false");
  }else if (
    to.fullPath == "/home/Rfid" ||
    to.fullPath == "/home/ProductCar" ||
    to.fullPath == "/home/Ciphertextface" ||
    to.fullPath == "/home/BrainPower" ||
    to.fullPath == "/home/EntranceGuard" ||
    to.fullPath == "/home/Archive" ||
    to.fullPath == "/home/IndustrialControl" || 
    to.fullPath == "/home/CloudStorage"
    
  ) {
    window.sessionStorage.setItem("active", "2");
    window.sessionStorage.setItem("showBackToTop", "2");
    window.sessionStorage.setItem("tabflag", "false");
  } else if (
    to.fullPath == "/home/MedicalTreatment" ||
    to.fullPath == "/home/Finance" ||
    to.fullPath == "/home/GovernmentAffairs" ||
    to.fullPath == "/home/Electricity" ||
    to.fullPath == "/home/Industry" ||
    to.fullPath == "/home/DataInteraction" ||
    to.fullPath == "/home/Privacy" 
    
  ) {
    window.sessionStorage.setItem("active", "6");
    window.sessionStorage.setItem("showBackToTop", "2");
    window.sessionStorage.setItem("tabflag", "false");
  } else if (
    to.fullPath.substring(0, to.fullPath.indexOf("?")) ==
      "/home/NewDetailsOne" ||
    to.fullPath == "/home/NewsTrends"
  ) {
    window.sessionStorage.setItem("active", "3");
    window.sessionStorage.setItem("showBackToTop", "2");
    window.sessionStorage.setItem("tabflag", "false");
  } else if (to.fullPath == "/home/CompanyIntroduction") {
    window.sessionStorage.setItem("active", "4");
    window.sessionStorage.setItem("showBackToTop", "2");
    window.sessionStorage.setItem("tabflag", "false");
  }

  // 可以在这里执行一些逻辑操作，例如记录日志或者权限验证等
  // if (from.fullPath == to.fullPath) {
  //   return
  // } else {
    next(); // 必须调用 next()，否则路由不会继续切换
  // }
});
export default router;
