
<template>
  <div
    style="
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="hexin-bg">
      <!-- <div class="title">
        <el-button @click="$router.push('/Customization')">立即咨询</el-button>
      </div> -->
    </div>
    <div class="contant">
      <div class="contant-box">
        <div class="chuantong">
          <div class="text">
            <h2>定义</h2>
            <p>
              隐私互联协议是一种通过加密计算和安全协作机制，在多个数据持有方之间实现数据共享和联合计算的协议框架。旨在确保数据隐私和安全性，同时支持跨组织、跨领域的数据交换与分析。通过规范化的数据输入输出接口、加密处理流程及风险控制机制，隐私互联协议为参与方提供了在不泄露原始数据的前提下进行数据融合、联合分析和模型训练的能力
            </p>
          </div>
          <div class="img">
            <img
              width="100%"
              src="../../assets/image/privacyInterconnect/Slice 83@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="fontContent">
          <h2>特点</h2>
          <ul>
            <li>
              <div>
                <span
                  ><img
                    src="../../assets/image/privacyInterconnect/Slice 88@2x.png"
                    alt=""
                /></span>
                数据隐私保护
              </div>
              <p>
                隐私互联协议通过全同态加密（FHE）、安全多方计算（SMPC）等技术，确保在数据交换和计算过程中各方的私有数据始终保持加密状态，不被泄露。参与方仅能处理加密数据，而无法访问或解读其他方的原始数据，从而有效防止数据泄露风险。
              </p>
            </li>
            <li>
              <div>
                <span
                  ><img
                    src="../../assets/image/privacyInterconnect/Slice 88@2x.png"
                    alt=""
                /></span>
                跨组织协作
              </div>
              <p>
                该协议支持多个组织或实体在不交换原始数据的情况下，进行数据共享与联合计算。通过隐私保护计算技术，参与方可以协同完成复杂的数据分析和决策任务，适用于多个行业和领域的跨组织协作场景。
              </p>
            </li>
            <li>
              <div>
                <span
                  ><img
                    src="../../assets/image/privacyInterconnect/Slice 88@2x.png"
                    alt=""
                /></span>
                风险控制与安全保障
              </div>
              <p>
                协议内嵌多层安全机制，包括数据加密、身份认证、通信加密和防篡改保护等，确保数据在存储、传输和计算过程中的完整性和保密性。通过全面的风险控制措施，隐私互联协议能够防止数据篡改、非法访问和其他潜在安全威胁。
              </p>
            </li>
            <li>
              <div>
                <span
                  ><img
                    src="../../assets/image/privacyInterconnect/Slice 88@2x.png"
                    alt=""
                /></span>
                标准化接口与合规性
              </div>
              <p>
                隐私互联协议提供标准化的数据输入输出接口，确保不同系统和平台之间的兼容性和互操作性。此外，协议遵循数据隐私和保护法规，在确保隐私的同时，支持合规的数据共享与计算。
              </p>
            </li>
            <li>
              <div>
                <span
                  ><img
                    src="../../assets/image/privacyInterconnect/Slice 88@2x.png"
                    alt=""
                /></span>
                高效性与可扩展性
              </div>
              <p>
                隐私互联协议在保证数据隐私的前提下，采用高效的加密计算方法，支持大规模数据的安全计算和分析。协议具备较高的可扩展性，能够适应多方协作的需求，并能在不断变化的技术环境中进行灵活调整和扩展
              </p>
            </li>
          </ul>
        </div>
        <div class="fontContent">
            <h2>应用</h2>
            <p>金融、医疗、能源、教育、制造、政务、学术等方面</p>
      
        <div class="chuantong2">
          <div class="img" style="margin: 0">
            <img
              width="100%"
              src="../../assets/image/privacyInterconnect/Slice 83@2x (1).png"
              alt=""
            />
          </div>
        </div>
    </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
export default {
  data() {
    return {
      showBox1: null,
    };
  },
  methods: {
    toggleBox(e) {
      const liElement = e.target; // 获取当前点击的元素
      const index = Array.from(liElement.parentNode.children).indexOf(
        liElement
      ); // 获取当前元素在父元素中的索引
      this.showBox1 = index;
    },
    toggleBoxout() {
      this.showBox1 = 5;
    },
  },
};
</script>
      
      <style lang="less" scoped>
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 2048px;
    background: url("../../assets/image/privacyInterconnect/Slice 90@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 922px;
      // width: 100%;
      transform: translateX(-50%);
      .el-button {
        width: 614px;
        height: 184px;
        background: #016eff;
        color: #fff;
        font-size: 72px;
        border-radius: 41px 41px 41px 41px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1160px;
    background: #fafcff;
    .contant-box {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      margin-top: 164px;
      padding: 0 82px;
      box-sizing: border-box;
      .chuantong {
        // display: flex;
        margin-bottom: 164px;
        .text {
          // flex: 1;
          h2 {
            font-size: 82px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin: 0;
            margin-bottom: 61px;
          }
          p {
            font-family: "refont";
            margin-top: 0;
            font-size: 61px;
            color: #566884;
            line-height: 123px;
          }
        }
        .img {
          position: relative;
          width: 1121px;
        //   height: 696px;
          background: #e2edff;
          padding: 41px;
          margin: 0 auto;
          box-sizing: border-box;
          p {
            position: absolute;
            bottom: -202px;
            font-size: 61px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .chuantong2 {
        display: flex;
        margin-bottom: 307px;
        flex-direction: column;
        h2 {
          font-size: 82px;
          color: #202124;
          font-weight: 400;
          font-family: "boldfont";
          margin: 0;
          margin-bottom: 61px;
        }
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "myfont";
            margin-top: 24px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .fontContent {
    font-size: 61px;
    font-family: "refont";
    color: #566884;
    margin-bottom: 72px;
    h2 {
      font-size: 82px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
    }
    ul {
      padding-inline-start: 0;
      li {
        >div {
            color: #016EFF;
        }
        span {
          display: inline-block;
          width: 61px;
          height: 61px;
          margin-right: 8px;
        
          img {
            width: 100%;
          }
        }
        p {
          padding-left: 28px;
          box-sizing: border-box;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 768px) {
  /* 添加适合大屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 550px;
    background: url("../../assets/image/privacyInterconnect/Slice 90@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 352px;
      width: 1200px;
      transform: translateX(-50%);
      .el-button {
        width: 240px;
        height: 60px;
        background: #016eff;
        color: #fff;
        font-size: 18px;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1378px;
    background: #fafcff;
    .contant-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      margin-top: 72px;
      .chuantong {
        display: flex;
        margin-bottom: 72px;
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "refont";
            margin-top: 24px;
            color: #566884;
            line-height: 40px;
          }
        }
        .img {
          width: 457px;
          //   height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
      }
      .chuantong2 {
        display: flex;
        margin-bottom: 72px;
        flex-direction: column;
        align-items: center;
        h2 {
          font-size: 24px;
          color: #202124;
          font-weight: 400;
          font-family: "boldfont";
          margin: 0 0 32px;
        }
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "myfont";
            margin-top: 24px;
          }
        }
        img {
          width: 1200px;
          margin-top: 10px;
        }
      }
    }
  }
  .fontContent {
    font-size: 20px;
    font-family: "refont";
    color: #566884;
    margin-bottom: 72px;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
    }
    ul {
      padding-inline-start: 0;
      li {
        >div {
            color: #016EFF;
        }
        span {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 8px;
        
          img {
            width: 100%;
          }
        }
        p {
          padding-left: 28px;
          box-sizing: border-box;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}
</style>