<template>
  <div
    style="
      width: 100%;
      background-color: #eef3fd;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 公司介绍背景图 -->
    <div class="company-bg">
      <div class="company-fontBox">
        <div class="company-font">关于我们</div>
      </div>
    </div>
    <!-- 公司信息 -->
    <div class="Company-info">
      <div class="qiye">
        <div>
          <h2>企业介绍</h2>
        </div>
        <div class="gongsijieshao">
          <p>
            北京隐算科技有限公司自2016年起就专注于隐私计算领域的深耕与突破，具备领先的后量子密码研发与生产能力，拥有自主研发的“隐算全同态加密技术”、抗量子密码技术，得到多位院士给予高度评价，填补了国内的技术空白；核心产品已通过公安部严格认证；累计获得超过50项授权专利和软件著作权，荣获国家级科技型中小企业、中关村高新技术企业、国家高新技术企业等多项荣誉，成为行业领军者          </p>
          <p>
            公司依托自主研发的隐算全同态加密技术，实现了数据全生命周期的全密态保护，从采集、传输、存储、计算、融合到销毁，数据始终处于加密状态，确保了“数据可用不可见”，并结合隐算密文+AI识别技术，实现了人脸、虹膜、声纹、体态等生物特征的无接触识别与验证，开创了零信任环境下的数据交互新模式          </p>
          <p>
            隐算科技集科研开发、生产经营、技术服务、系统集成为一体，产品广泛应用于金融、保险、公共安全、国防、政府服务、通信、医疗健康、智能安防、汽车制造、物流运输等多个关键领域，为推动社会各行业的数字化转型与创新发展提供了强有力的数据安全金字塔底座
          </p>
        </div>
        <div class="licheng">
          <h2>发展历程</h2>
          <div class="shoujilicheng">
            <div class="container" ref="container">
              <div
                class="page"
                v-for="(page, index) in pages"
                :key="index"
                :style="{ backgroundColor: page.color }"
              >
                <h2>{{ page.title }}</h2>
                <p v-for="item in page.text" :key="item">
                  <span></span>{{ item }}
                </p>
              </div>
            </div>
            <div class="lichengFlag">
              <div
                v-for="(item, index) in pages"
                :key="index"
                class="lichengFlagItem"
                :class="index == currentPage ? 'active' : ''"
              ></div>
            </div>
          </div>
          <img src="../assets/image/guanyu/Slice 97@2x.png" alt="" />
        </div>
        <div class="infoData">
          <div class="item">
            <img src="../assets/image/guanyu/Slice 94@2x.png" alt="" />
            <div class="text">
              <h2>25 <strong>项</strong></h2>
              <span>国家发明专利</span>
            </div>
          </div>
          <div class="item">
            <img src="../assets/image/guanyu/Slice 94@2x(1).png" alt="" />
            <div class="text">
              <h2>8 <strong>项</strong></h2>
              <span>正在审批</span>
            </div>
          </div>
          <div class="item">
            <img src="../assets/image/guanyu/Slice 94@2x(2).png" alt="" />
            <div class="text">
              <h2>10 <strong>项</strong></h2>
              <span>计算机软件著作权</span>
            </div>
          </div>
          <div class="item">
            <img src="../assets/image/guanyu/Slice 94@2x(3).png" alt="" />
            <div class="text">
              <h2>30 <strong>项</strong></h2>
              <span>核心论文</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司荣誉 -->
    <div class="companyHonor-box">
      <div class="companyHonor-content">
        <h2
          class="companyHonor-title"
          style="color: #202124; font-family: 'boldfont'"
        >
          企业荣誉
        </h2>
        <div class="companyHonor-partner">
          <div
            style="overflow: hidden; display: flex; align-items: center"
            :style="`width:${partentArray.length * valueBasedOnWidth}rem`"
          >
            <div
              v-for="(item, index) in partentArray"
              :key="index"
              :style="{ transform: `translateX(${xOffset}rem)` }"
              style="
                float: left;
                border-radius: 8px 8px 8px 8px;
                border: 2px solid #b4c6d0;
              "
            >
              <!-- src="../assets/image/company/certificate1.png" -->
              <!-- <img
                src="../assets/image/news-new/Slice 80@2x (21).png"
                style="width: 100%"
                alt=""
              /> -->
              <!-- <h3>{{ item.patentCreateDate.slice(0, 10) }}</h3>
              <p>{{ item.patentName }}</p> -->
              <img :src="item.patentPic" style="width: 100%" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dibu">
      <div class="text">
        <p>开创全密时代 助力数字强国</p>
        <el-button @click="$router.push('/Customization')">合作咨询</el-button>
      </div>
    </div>
  </div>
</template>
        
  <script>
import { acquirePatentInfo, patentInfo } from "../api/patent";
export default {
  data() {
    return {
      screenWidth: 0, // 屏幕宽度
      startX: 0,
      currentPage: 4,
      pages: [
        {
          title: "2016-2019",
          content: "",
          text: [
            "实现同态加密技术突破",
            "申请多项科研专利",
            "图文数据密文处理",
            "音频密文处理",
            "视频密文处理",
          ],
          color: "#fff",
        },
        {
          title: "2020",
          content: "",
          text: [
            "实现人脸盲处理器及身份盲识别",
            "同年完成密文采集、密文传输、密文演算，实现数据在全密文下运行",
          ],
          color: "#fff",
        },
        {
          title: "2021",
          content: "",
          text: [
            "完成 第二代同态加密技术 开发，构建专利体系，技术水平达到世界领先水平",
            "大大提高 图像，声音，视频，身份识别盲处理、盲运算 的速度，达到了 可应用化 的程度",
          ],
          color: "#fff",
        },
        {
          title: "2022",
          content: "",
          text: [
            "公司正式成立：实现结构化数据与非结构化数据同态加密技术实用化",
            "开展 数据中心、数据库同态加密系统研发",
            "与军方某单位开展某光纤信息传输体系同态加密系统验证试验",
          ],
          color: "#fff",
        },
        {
          title: "2023",
          content: "",
          text: [
            "承接人民银行全同态加密数据库强国重大工程",
            "与军方某单位合作开展 全同态加密通信技术应用落地",
            '上海市科技委员会2023年度"科技创新行动计划"同态加密技术可验证结果专项科研项目结题',
            "承接中国移动软件集成工业物联网智慧园区信息安全项目",
            "获得密码学院士、航天信息密码专家、军内密码顶级专家技术认可",
            "完成第三代隐算全同态加密技术研发，并获得发明专利",
            "获得2023年装备发展部正向挑战赛技术优胜奖",
            "已通过公安部一所关于隐算密文人脸识别系统产品的验证测试，性能级别为一级",
          ],
          color: "#fff",
        },
        {
          title: "2024",
          content: "",
          text: [
            "已通过公安部一所关于隐算密文门禁-身份识别系统产品的验证测试，性能级别为一级",
            "已通过公安部一所关于隐算密文RFID文件物资管理系统产品的验证测试",
          ],
          color: "#fff",
        },
      ],
      rootFontSize: null, // 用于存储根标签的字体大小
      items: [
        // 盒子的数据
      ],
      xOffset: 0, // 水平偏移量
      interval: null, // 定时器
      partentArray: [],
    };
  },
  async created() {
    const { data } = await acquirePatentInfo(1, 10, 1);
    console.log(data);
    this.partentArray = data.data.content.concat(data.data.content);
  },
  // created() {},
  mounted() {
    this.showPage(this.currentPage); // Set the initial page
    this.$refs.container.addEventListener("touchstart", this.handleTouchStart,{ passive: false });
    this.$refs.container.addEventListener("touchmove", this.handleTouchMove,{ passive: false });
    this.getRootFontSize(); // 初始化时获取一次根标签字体大小
    window.addEventListener("resize", this.getRootFontSize); // 监听窗口的resize事件
    this.startScroll(); // 页面加载后开始滚动
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getRootFontSize); // 组件销毁前移除resize事件监听
    this.$refs.container.removeEventListener(
      "touchstart",
      this.handleTouchStart
    );
    this.$refs.container.removeEventListener("touchmove", this.handleTouchMove);
  },
  computed: {
    valueBasedOnWidth() {
      return this.screenWidth > 767 ? "1.452631" : "4.42105";
    },
  },
  methods: {
    handleTouchStart(event) {
      // event.preventDefault(); // 阻止默认行为
      event.stopPropagation(); // 阻止事件冒泡
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      // event.preventDefault(); // 阻止默认行为
      event.stopPropagation(); // 阻止事件冒泡
      const moveX = event.touches[0].clientX;
      const deltaX = this.startX - moveX;

      if (Math.abs(deltaX) > 50) {
        // Minimum swipe distance
        if (deltaX > 0) {
          // Swipe left
          this.showPage(this.currentPage + 1);
        } else {
          // Swipe right
          this.showPage(this.currentPage - 1);
        }
        this.startX = moveX; // Update startX for continuous swiping
      }
    },
    showPage(pageIndex) {
      if (pageIndex < 0) pageIndex = 0;
      if (pageIndex >= this.pages.length) pageIndex = this.pages.length - 1;

      this.currentPage = pageIndex;
      this.$refs.container.style.transform = `translateX(-${
        this.currentPage * 100
      }vw)`;
    },
    getRootFontSize() {
      this.screenWidth = window.innerWidth;
      console.log(this.screenWidth);
      const computedStyle = getComputedStyle(document.documentElement);
      this.rootFontSize = parseFloat(computedStyle.fontSize);
    },
    startScroll() {
      this.interval = setInterval(() => {
        if (
          this.xOffset <=
          (-this.partentArray.length / 2) * this.valueBasedOnWidth // * this.rootFontSize
          // (-this.partentArray.length / 2) * 1.41667 * this.rootFontSize
        ) {
          this.xOffset = 0;
        }
        this.xOffset = this.xOffset - 1 / 190; // 每秒移动的距离，你可以根据需要自行调整
      }, 1000 / 120); // 60帧每秒，这样可以实现平滑的动画效果
    },
  },
  beforeDestroy() {
    clearInterval(this.interval); // 组件销毁前清除定时器
  },
};
</script>
        
        <style lang="less" scoped>
// Slice 80@2x (23)
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  .company-bg {
    color: #fff;
    width: 100%;
    height: 2048px;
    background: url("../assets/image/guanyu/Slice 80@2x (23).png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .company-fontBox {
      width: 100%;
      // padding: 0 82px;
      // box-sizing: border-box;
      margin: 0 auto;
    }
    .company-font {
      display: none;
      margin-top: 204px;
      float: left;
      height: 96px;
      width: 100%;
      font-size: 40px;
      color: #202124;
      font-family: "boldfont";
    }
  }
  .Company-info {
    width: 100%;
    background: #fafcff;
    // padding: 0 82px;
    //   box-sizing: border-box;
    .qiye {
      width: 100%;
      margin: 0 auto;
      margin-top: 164px;
      h2 {
        font-weight: 400;
        font-family: "boldfont";
        font-size: 82px;
        color: #202124;
        margin: 0px 0 102px;
        text-align: center;
        background: #fafcff;
      }
      .gongsijieshao {
        margin-bottom: 164px;
        width: 1756px;
        // height: 2058px;
        background: linear-gradient(45deg, #fafdff 0%, #f5faff 100%);
        box-shadow: 0px 0 76px 0px rgba(191, 209, 220, 0.5);
        border-radius: 61px 61px 61px 61px;
        border: 10px solid #ffffff;
        padding: 123px;

        box-sizing: border-box;
        margin: 0 auto 164px;
        p {
          margin: 0 0 82px 0;
          font-size: 61px;
          color: #202124;
          font-family: "myfont";
          line-height: 117px;
          color: #202124;
        }
      }
      .licheng {
        background: #fff !important;
        padding-top: 123px;
        img {
          display: none;
        }
        h2 {
          background: #fff;
        }
        .shoujilicheng {
          position: relative;
          margin-top: 60px;
          display: block;
          width: 100%;
          height: 1800px;
          background: transparent;
          overflow: hidden;
          .lichengFlag {
            position: absolute;
            bottom: 123px;
            margin: 0 82px;
            width: 1756px;
            box-sizing: border-box;
            height: 20px;
            background: #e4eaf0;
            display: flex;
            .lichengFlagItem {
              flex: 1;
              height: 20px;
            }
            .active {
              background: #016eff;
            }
          }
          .container {
            display: flex;
            transition: transform 0.3s ease;
            width: 100%; /* Three pages in a row */
            height: 1800px;
            touch-action: auto;
          }

          .page {
            width: 100%; /* Full viewport width */
            height: 100%; /* Full viewport height */
            flex-shrink: 0; /* Prevent pages from shrinking */
            box-sizing: border-box;
            font-size: 61px;
            color: white;
            padding: 0 82px;
            box-sizing: border-box;
            h2 {
              font-size: 82px;
              color: #016eff;
              font-family: "boldfont";
              text-align: start;
            }
            p {
              font-size: 61px;
              color: #566884;
              line-height: 80px;
              font-family: "refont";
              display: flex;
              span {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-top: 30px;
                background: #016eff;
                border-radius: 128px 128px 128px 128px;
                margin-right: 31px;
              }
            }
          }
        }
      }
      img {
        // display: none;
        width: 100%;
      }
    }
    .origin {
      margin-right: 9px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: #1450bb;
      border-radius: 33px 33px 33px 33px;
    }
    .Company-box {
      width: 1200px;
      height: 480px;
      margin: 120px auto 140px;
      display: flex;
      justify-content: space-between;
      p {
        font-family: myfont;
      }
    }
    .Company-img {
      width: 590px;
      height: 480px;
      background: url("../assets/image/guanyu/Slice 97@2x.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .infoData {
      margin-top: 0px;
      width: 100%;
      height: 410px;
      padding: 0 82px;
      box-sizing: border-box;
      // display: flex;
      // overflow: hidden;
      // justify-content: space-between;
      .item {
        float: left;
        width: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-top: 133px;
        img {
          // float: left;
          width: 410px;
          height: 410px;
          margin-right: 12px;
        }
        .text {
          // float: left;
          display: flex;
          flex-direction: column;

          height: 100%;
          h2 {
            font-size: 184px;
            color: #016eff;
            font-weight: 400;
            font-family: "boldfont";
            margin: 0;
            margin-top: 20px;
            strong {
              font-size: 61px;
              color: #000;
            }
          }
          span {
            font-size: 61px;
            color: #3c3c3c;
            font-family: "boldfont";
          }
        }
      }
    }
  }
  // 公司荣誉
  .companyHonor-box {
    width: 100%;
    // height: 730px;
    background-color: #fff;
    background: #fafcff;
    .companyHonor-content {
      box-sizing: border-box;
      width: 100%;
      padding: 0 82px;
      box-sizing: border-box;
      margin: 120px auto 164px;
      .companyHonor-title {
        height: 60px;
        text-align: center;
        font-size: 82px;
        color: #202124;
        // font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        // font-weight: bold;
        color: #000000;
        line-height: 47px;
        margin-bottom: 87px;
      }
      // 合作方
      .companyHonor-partner {
        overflow: hidden;
        width: 100%;
        > div > div {
          width: 758px;
          height: 573px;
          float: left;
          margin-right: 82px;
          box-sizing: border-box;
          background: #fff;
          text-align: center;
          border-radius: 41px 41px 41px 41px;
          border: 5px solid #b4c6d0;
          overflow: hidden;
        }
        img {
          height: 573px;
          border-radius: 41px 41px 41px 41px;
          border: none;
        }
        h3 {
          font-size: 61px;
          color: #3c3c3c;
          line-height: 72px;
          font-weight: 400;
          font-family: "boldfont";
          padding-bottom: 35px;
          padding-top: 31px;
          text-align: left;
          padding-left: 41px;
          box-sizing: border-box;
          margin: 0;
        }
        p {
          margin: 0;
          font-size: 61px;
          color: #3c3c3c;
          line-height: 72px;
          font-family: "refont";
          text-align: left;
          padding-left: 41px;
          box-sizing: border-box;
        }
        // display: flex;
        // justify-content: space-between;
        .partner > div {
          width: 240px;
          height: 132px;
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
        }
      }
    }
  }
  // 底部
  .dibu {
    position: relative;
    width: 100%;
    height: 819px;
    background: url("../assets/image/guanyu/Slice 95@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .text {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 225px;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-size: 92px;
        color: #202124;
        margin: 0;
        font-family: "boldfont";
      }
      ::v-deep .el-button {
        width: 451px;
        height: 133px;
        background: url("../assets/image/guanyu/Slice 85@2x.png");
        background-repeat: no-repeat;
        background-size: cover;
        font-size: 61px;
        color: #ffffff;
        font-family: "refont";
        margin-top: 133px;
        cursor: pointer;
      }
    }
  }
}

/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  .company-bg {
    color: #fff;
    width: 100%;
    height: 550px;
    background: url("../assets/image/guanyu/Slice 90@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .company-fontBox {
      width: 1200px;
      margin: 0 auto;
    }
    .company-font {
      margin-top: 204px;
      float: left;
      height: 96px;
      width: 100%;
      font-size: 40px;
      color: #202124;
      font-family: "boldfont";
    }
  }
  .Company-info {
    width: 100%;
    background: #fff;
    background: #fafcff;
    .qiye {
      width: 1200px;
      margin: 0 auto;
      h2 {
        font-weight: 400;
        font-family: "boldfont";
        font-size: 32px;
        color: #202124;
        margin: 56px 0 40px;
        text-align: center;
      }
      .gongsijieshao {
        width: 1200px;
        // height: 348px;
        background: linear-gradient(45deg, #fafdff 0%, #f5faff 100%);
        box-shadow: 0px 0px 21px 0px rgba(191, 209, 220, 0.5);
        border-radius: 12px 12px 12px 12px;
        border: 2px solid #ffffff;
        padding: 26px 67px;
        box-sizing: border-box;

        p {
          margin: 28px 0;
          font-size: 16px;
          color: #202124;
          font-family: "myfont";
          line-height: 30px;
          color: #202124;
        }
      }
      .licheng {
        img {
          display: block;
        }
        .shoujilicheng {
          display: none;
        }
      }
      img {
        width: 100%;
      }
    }
    .origin {
      margin-right: 9px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: #1450bb;
      border-radius: 33px 33px 33px 33px;
    }
    .Company-box {
      width: 1200px;
      height: 480px;
      margin: 120px auto 140px;
      display: flex;
      justify-content: space-between;
      p {
        font-family: myfont;
      }
    }
    .Company-img {
      width: 590px;
      height: 480px;
      background: url("../assets/image/guanyu/Slice 97@2x.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .infoData {
      margin-top: 109px;
      width: 100%;
      height: 136px;
      display: flex;
      justify-content: space-between;
      .item {
        img {
          float: left;
          width: 136px;
          height: 136px;
          margin-right: 12px;
        }
        .text {
          float: left;
          height: 100%;
          h2 {
            font-size: 40px;
            color: #016eff;
            font-weight: 400;
            font-family: "boldfont";
            margin: 0;
            margin-top: 20px;
            strong {
              font-size: 16px;
              color: #000;
            }
          }
          span {
            font-size: 16px;
            color: #3c3c3c;
            font-family: "boldfont";
          }
        }
      }
    }
  }
  // 公司荣誉
  // .companyHonor-box {
  //   width: 100%;
  //   height: 730px;
  //   background-color: #fff;
  //   background: #fafcff;
  //   .companyHonor-content {
  //     box-sizing: border-box;
  //     width: 1200px;
  //     margin: 120px auto;
  //     .companyHonor-title {
  //       height: 60px;
  //       text-align: center;
  //       font-size: 32px;
  //       color: #202124;
  //       // font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  //       // font-weight: bold;
  //       color: #000000;
  //       line-height: 47px;
  //       margin-bottom: 60px;
  //     }
  //     // 合作方
  //     .companyHonor-partner {
  //       overflow: hidden;
  //       width: 1200px;
  //       > div > div {
  //         width: 240px;
  //         float: left;
  //         margin-right: 100px;
  //         box-sizing: border-box;
  //         text-align: center;
  //       }
  //       // display: flex;
  //       // justify-content: space-between;
  //       .partner > div {
  //         width: 240px;
  //         height: 132px;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         float: left;
  //       }
  //     }
  //   }
  // }
    // 公司荣誉
    .companyHonor-box {
    width: 100%;
    // height: 730px;
    background-color: #fff;
    background: #fafcff;
    padding: 0 15px;
    box-sizing: border-box;
    .companyHonor-content {
      box-sizing: border-box;
      width: 100%;
      margin: 120px auto;
      .companyHonor-title {
        height: 60px;
        text-align: center;
        font-size: 32px;
        color: #202124;
        // font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        // font-weight: bold;
        color: #000000;
        line-height: 47px;
        margin-bottom: 60px;
      }
      // 合作方
      .companyHonor-partner {
        overflow: hidden;
        width: 100%;
        > div > div {
          width: 246px;
          height: 187px;
          float: left;
          margin-right: 30px;
          box-sizing: border-box;
          text-align: center;
          overflow: hidden;
        }
        img {
          height: 187px;
          border-radius: 8px 8px 8px 8px;
        }
        // display: flex;
        // justify-content: space-between;
        .partner > div {
          width: 240px;
          height: 132px;
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
        }
      }
    }
  }
  // 底部
  .dibu {
    position: relative;
    width: 100%;
    height: 240px;
    background: url("../assets/image/guanyu/Slice 95@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .text {
      position: absolute;
      width: 1200px;
      left: 50%;
      top: 63px;
      transform: translateX(-50%);
      p {
        font-size: 28px;
        color: #202124;
        margin: 0;
        font-family: "boldfont";
      }
      ::v-deep .el-button {
        width: 118px;
        height: 40px;
        background: url("../assets/image/guanyu/Slice 85@2x.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        font-size: 14px;
        color: #ffffff;
        font-family: "refont";
        margin-top: 35px;
        cursor: pointer;
      }
    }
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  .company-bg {
    color: #fff;
    width: 100%;
    height: 550px;
    background: url("../assets/image/guanyu/Slice 90@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .company-fontBox {
      width: 1200px;
      margin: 0 auto;
    }
    .company-font {
      margin-top: 204px;
      float: left;
      height: 96px;
      width: 100%;
      font-size: 40px;
      color: #202124;
      font-family: "boldfont";
    }
  }
  .Company-info {
    width: 100%;
    background: #fff;
    background: #fafcff;
    .qiye {
      width: 1200px;
      margin: 0 auto;
      h2 {
        font-weight: 400;
        font-family: "boldfont";
        font-size: 32px;
        color: #202124;
        margin: 56px 0 40px;
        text-align: center;
      }
      .gongsijieshao {
        width: 1200px;
        // height: 348px;
        background: linear-gradient(45deg, #fafdff 0%, #f5faff 100%);
        box-shadow: 0px 0px 21px 0px rgba(191, 209, 220, 0.5);
        border-radius: 12px 12px 12px 12px;
        border: 2px solid #ffffff;
        padding: 26px 67px;
        box-sizing: border-box;

        p {
          margin: 28px 0;
          font-size: 16px;
          color: #202124;
          font-family: "myfont";
          line-height: 30px;
          color: #202124;
        }
      }
      .licheng {
        img {
          display: block;
        }
        .shoujilicheng {
          display: none;
        }
      }
      img {
        width: 100%;
      }
    }
    .origin {
      margin-right: 9px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: #1450bb;
      border-radius: 33px 33px 33px 33px;
    }
    .Company-box {
      width: 1200px;
      height: 480px;
      margin: 120px auto 140px;
      display: flex;
      justify-content: space-between;
      p {
        font-family: myfont;
      }
    }
    .Company-img {
      width: 590px;
      height: 480px;
      background: url("../assets/image/guanyu/Slice 97@2x.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .infoData {
      margin-top: 109px;
      width: 100%;
      height: 136px;
      display: flex;
      justify-content: space-between;
      .item {
        img {
          float: left;
          width: 136px;
          height: 136px;
          margin-right: 12px;
        }
        .text {
          float: left;
          height: 100%;
          h2 {
            font-size: 40px;
            color: #016eff;
            font-weight: 400;
            font-family: "boldfont";
            margin: 0;
            margin-top: 20px;
            strong {
              font-size: 16px;
              color: #000;
            }
          }
          span {
            font-size: 16px;
            color: #3c3c3c;
            font-family: "boldfont";
          }
        }
      }
    }
  }
  // 公司荣誉
  .companyHonor-box {
    width: 100%;
    // height: 730px;
    background-color: #fff;
    background: #fafcff;
    padding: 0 15px;
    box-sizing: border-box;
    .companyHonor-content {
      box-sizing: border-box;
      width: 100%;
      margin: 120px auto;
      .companyHonor-title {
        height: 60px;
        text-align: center;
        font-size: 32px;
        color: #202124;
        // font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        // font-weight: bold;
        color: #000000;
        line-height: 47px;
        margin-bottom: 60px;
      }
      // 合作方
      .companyHonor-partner {
        overflow: hidden;
        width: 100%;
        > div > div {
          width: 246px;
          height: 187px;
          float: left;
          margin-right: 30px;
          box-sizing: border-box;
          text-align: center;
          overflow: hidden;
        }
        img {
          height: 187px;
          border-radius: 8px 8px 8px 8px;
        }
        // display: flex;
        // justify-content: space-between;
        .partner > div {
          width: 240px;
          height: 132px;
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
        }
      }
    }
  }
  // 底部
  .dibu {
    position: relative;
    width: 100%;
    height: 240px;
    background: url("../assets/image/guanyu/Slice 95@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .text {
      position: absolute;
      width: 1200px;
      left: 50%;
      top: 63px;
      transform: translateX(-50%);
      p {
        font-size: 28px;
        color: #202124;
        margin: 0;
        font-family: "boldfont";
      }
      ::v-deep .el-button {
        width: 118px;
        height: 40px;
        background: url("../assets/image/guanyu/Slice 85@2x.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        font-size: 14px;
        color: #ffffff;
        font-family: "refont";
        margin-top: 35px;
        cursor: pointer;
      }
    }
  }
}
</style>
  